.product-images {
  width: 250px;
  height: 15rem !important;
}

.description-border {
  width: 155px;

  border-bottom: 2px solid #0064e5;
  border-radius: 3px 3px 0px 0px;
}

.description-value {
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #000000 !important;
}

/* New Styling */

.main-image {
  height: 13.75rem;
  width: 100%;
  object-fit: cover;
}

.box {
  height: 3rem;
  width: 5rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.box:hover {
  border: 2px solid #0064e5;
}

.box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-detailss {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 1rem 0rem;
  flex-wrap: wrap;
}

.product-detailss .product-infoo {
  text-align: center;
  padding: 10px 20px;
}

.main-cont {
  gap: 1rem;
}

.detailss {
  display: flex;
  justify-content: flex-start;
}

.title {
  font-size: 0.85rem;
  font-weight: 900;
  color: #000000;
  width: 155px;
  text-align: left;
}

.detailss .px-4 {
  font-size: 12px;
}

.detailss .spec {
  text-align: left;
  font-size: 12px !important;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .title {
    font-size: 12px;
    width: 155px;
  }

  .detailss .spec {
    font-size: 10px !important;
  }
}
