body {
  margin: 0;
  padding: 0;
  color: #282828;
  box-sizing: border-box;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;
}

.menu-icon {
  position: fixed; /* Needs to stay visible for all mobile scrolling */
  display: flex;
  top: 5px;
  left: 10px;
  align-items: center;
  justify-content: center;
  background-color: #dadae3;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
}

.category-dropdown-item {
  font-size: 0.8rem !important;
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 0 55px;
}

/* Make room for the menu icon on mobile */
.header__search {
  display: flex;
  align-items: center;
  width: 90%;
}

.header__search form {
  margin: 0;
  width: 100%;
}

.header__message h2 {
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}

.custom__search {
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.08);
}

.custom__search input {
  margin-left: 10px;
  border: none;
  font-size: 0.85rem;
}

.notify-wrap .icon-notification {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 8px;
}

.buyer {
  background-color: #0064e5;
}

.seller {
  background-color: #dc4d04;
}

/* media query styling for side bar container */
.media-sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  position: fixed;
  overflow-y: auto;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); */
  z-index: 5; /* Needs to sit above the hamburger menu icon */
}

.sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  position: fixed;
  overflow-y: auto;
  transform: translateX(-250px);
  transition: all 0.6s ease-in-out;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); */
  z-index: 2; /* Needs to sit above the hamburger menu icon */
}

/* media query styling for menu icon */
.media-menuu-icon {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0.2rem;
  left: 1%;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 0.8rem;
  cursor: pointer;
  z-index: 6;
}

.media-menuu-icon > .line1 {
  transform: rotate(-405deg) translate(-0.1rem, 0.4rem);
}
.media-menuu-icon > .line2 {
  opacity: 0;
}
.media-menuu-icon > .line3 {
  transform: rotate(405deg) translate(-0.2rem, -0.5rem);
}

/* menu icon */
.menuu-icon {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0.2rem;
  left: 1%;
  height: 1.5rem;
  width: 1.2rem;
  margin-top: 0.8rem;
  cursor: pointer;
}

.menuu-icon > .line {
  background-color: #112840;
  width: 100%;
  height: 0.2rem;
  margin-bottom: 0.2rem;
}

.media-menuu-icon > .line1,
.media-menuu-icon > .line3 {
  transition: all 0.4s;
}

.menuu-icon > .line1,
.menuu-icon > .line3 {
  transition: all 0.4s;
}

.user-area {
  display: none;
  margin: 0 0 0;
  padding: 20px 20px 20px 40px;
}

.user-area p {
  font-size: 0.9rem;
  color: #282828;
  margin: 0;
  padding: 0;
}

.user-area .user-area-art {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #777;
  color: #fff;
  font-size: 16px;
  padding: 8px 15px;
}

.sidenav.active {
  transform: translateX(0);
}

.sidenav .switch-wrap {
  width: 72%;
  padding: 15px 20px 15px 40px;
}

.sidenav__list {
  padding: 0;
  margin-top: 0px;
  list-style-type: none;
}

.sidenav__list .margin-top {
  flex-grow: 1;
}

.sidenav__list-item .list_icon {
  margin-right: 15px;
}

.main {
  grid-area: main;
  background-color: #f9f9f9;
  padding: 25.25px 10px;
}

.main .section-title-ctn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
}

.main .section-title {
  font-size: 1rem;
  margin: 10px 0 15px;
  padding: 0;
}

.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  /* grid-auto-rows: 94px; */
  grid-gap: 20px;
  margin-bottom: 50px;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding-btm {
  padding-bottom: 0 !important;
}

.overview-card {
  padding: 30px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
  margin-bottom: 25px;
}

.overview-card .bold {
  font-weight: 900 !important;
  width: 150px;
}

.overview-card h2 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.overview-card h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #282828;
  margin: 0;
  padding: 0 0 0;
}

.overview-card p {
  font-size: 0.8rem;
  font-weight: 300;
  color: #5c5c5c !important;
}

.overview-card .table-responsive {
  overflow-x: scroll;
}

.overview-card table {
  margin-bottom: 0;
}

.overview-card table thead tr th {
  font-size: 0.85rem;
  font-weight: 600 !important;
  border: none;
  padding: 18px 15px;
}

.overview-card table tr th {
  font-size: 0.85rem;
  font-weight: normal !important;
  border: none;
  padding: 18px 15px;
  box-sizing: content-box;
  white-space: nowrap;
  text-align: center;
}

.overview-card table tbody tr td {
  font-size: 0.8rem;
  font-weight: 600;
  border: none;
  padding: 15px 15px;
  box-sizing: content-box;
  white-space: nowrap;
  text-align: center;
}

.overview-card table tbody tr .first-td {
  font-weight: 900;
  width: 100px;
  text-align: start;
}
.overview-card table tbody tr .second-td {
  text-align: start;
  white-space: pre-wrap;
}

.overview-card table tbody tr td .px-1 {
  white-space: pre-wrap;
}

.overview-card table tbody tr td .table-product-img {
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
}

.overview-card table tbody tr td p {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 !important;
  color: #282828 !important;
}

/* .overview-card table tbody tr td .text-warning {
      color: #D8D020!important;
    } */

.overview-card table tbody tr td .text-primary {
  color: #0083d3 !important;
}

.overview-card table tbody tr td .text-success {
  color: #4aae4e !important;
}

.overview-card table tbody tr td .table-order-no {
  font-size: 0.7rem;
  font-weight: 300;
}

.overview-card .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: rgba(205, 219, 227, 0.01) !important;
}

.overview-card .overview-card-link {
  font-size: 0.8rem;
  color: #0083d3;
  align-self: end;
}

.info-cards {
  column-count: 1;
  column-gap: 20px;
}

.card {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 35px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  min-height: 200px;
}

.card h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.card p {
  font-size: 0.9rem;
}

/* Force varying colors to simulate dynamic content */
.info-cards .card:first-child {
  background-color: rgba(255, 248, 181, 1);
}

.info-cards .card:nth-child(2) {
  background-color: rgba(255, 221, 204, 1);
}

/* Message Center */

.chat-container {
  display: grid;
  grid-template-columns: 1fr;
  /* height: 684px; */
}

/* Chat header */

.chat-wrap .chat-header {
  padding: 18px 30px;
  border-bottom: 1px solid #e4e4e4;
}

.chat-wrap .chat-header .chat-rep-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #e7f3fb;
}

.online-status {
  width: 10px;
  height: 10px;
  background-color: #04a72d;
  border-radius: 50%;
  bottom: 0;
  right: 5px;
}

.chat-wrap .chat-header h2 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 1.5px;
  padding: 0;
}

.chat-wrap .chat-header p {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #5c5c5c;
}

.chat-wrap .chat-header .chat-icon {
  margin: 10px 0 0 25px;
}

.chat-icon:hover,
.send-icon:hover {
  cursor: pointer;
}

/* End of chat header */

.chat-wrap .chat-area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 450px 150px;
}

/* Chat messages */

.chat-wrap .chat-area .message-area {
  background-color: #ffffff;
  padding: 25px 30px;
  overflow-y: scroll;
  /* border-bottom: 1px solid #E4E4E4; */
}

.chat-wrap .chat-area .message-area .chat-order-request-msg {
  max-width: 45%;
  background-color: #ffffff !important;
  padding: 30px;
  border-radius: 16px 16px 0px 16px !important;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.1);
  margin-bottom: 40px;
}

.chat-wrap .chat-area .message-area .order-msg img {
  width: 80px;
  border-radius: 2px;
}

.chat-wrap .chat-area .message-area .order-msg h2 {
  margin: 5px 0 5px;
  padding: 0;
  font-size: 1rem;
  color: #dc4d04;
}

.chat-wrap .chat-area .message-area .order-msg p {
  margin: 0 0 0;
  padding: 0;
  font-size: 0.85rem;
  font-weight: 400;
  color: #282828 !important;
}

.chat-wrap .chat-area .message-area .order-msg p span {
  color: #5c5c5c;
  font-size: 0.7rem;
}

.chat-wrap .chat-area .message-area .chat-msg {
  max-width: 45% !important;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  font-size: 0.85rem;
  margin-bottom: 40px;
  clear: both;
}

.chat-wrap .chat-area .message-area .chat-msg p {
  font-size: 0.85rem;
  color: #282828 !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.chat-wrap .chat-area .message-area .chat-order-request-msg .chat-timestamp {
  font-size: 0.7rem !important;
  margin: 10px 0 0;
  font-weight: 400;
  color: rgb(182, 182, 182) !important;
}

.chat-wrap .chat-area .message-area .chat-order-request-msg .order_msg-btn {
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: rgba(11, 34, 57, 0.81);
  border-radius: 50px;
  font-size: 0.8rem;
  margin: 15px 10px 5px 0;
  padding: 6px 15px !important;
}

.chat-wrap
  .chat-area
  .message-area
  .chat-order-request-msg
  .order_msg-btn:hover {
  background-color: rgba(220, 77, 4, 0.1);
  border-color: rgba(220, 77, 4, 0.3);
}

.chat-wrap .chat-area .message-area .chat-msg .chat-timestamp {
  font-size: 0.7rem;
  margin: 10px 0 0;
  font-weight: 400;
  color: rgb(182, 182, 182) !important;
}

.chat-wrap .chat-area .message-area .sender {
  float: right;
  background-color: rgba(120, 190, 247, 0.1);
  border-radius: 16px 16px 0px 16px !important;
}

.chat-wrap .chat-area .message-area .receiver {
  float: left;
  background-color: rgba(220, 77, 4, 0.1);
  border-radius: 16px 16px 16px 0px !important;
}

/* End of chat messages */

/* Chat message input */

.chat-wrap .chat-area .message-input {
  width: 100%;
  padding: 30px 30px 0;
}

.chat-wrap .chat-area .message-input .msg-center-btn {
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: rgba(11, 34, 57, 0.81);
  border-radius: 4px !important;
  font-size: 0.8rem;
  margin-bottom: 5px;
  padding: 6px 15px !important;
}

.chat-wrap .chat-area .message-input .msg-center-btn:hover {
  background-color: rgba(220, 77, 4, 0.1);
  border-color: rgba(220, 77, 4, 0.3);
}

.chat-wrap .chat-area .message-input .chat-form .chat-input-area {
  height: 50px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  align-items: center;
}

.chat-wrap .chat-area .message-input .chat-form {
  margin: 10px 0;
}

.chat-wrap .chat-area .message-input .chat-form .chat-input-area:focus-within {
  border-color: #bbdef6;
}

.chat-wrap .chat-area .message-input .chat-form .chat-input-area .send-icon {
  margin-right: 25px;
}

.chat-wrap .chat-area .message-input .chat-form textarea {
  border: none;
  font-size: 0.9rem;
  padding: 12px 15px;
  resize: none;
  align-items: center;
  height: 50px;
}

/* end of chat message input */

/* Paginantion */

.pagination .page-item {
  margin: 0 10px;
}

.pagination .page-item .page-link {
  border-radius: 4px !important;
  border: none !important;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 500;
  color: #7f8d96;
}

.pagination .page-item .page-link:hover {
  color: #0083d3;
}

.pagination .page-item.active .page-link {
  background-color: #0083d3;
  color: #ffffff;
}

/* End of Pagination */

.footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  color: #5c5c5c;
  padding: 10px 55px;
  font-size: 0.85rem;
}

.view-seller {
  text-align: left;
  margin-top: 20px;
  font-size: 18px;
  text-decoration: underline;
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
  .grid-container {
    grid-template-columns: 240px 1fr;
    grid-template-areas:
      "sidenav header"
      "sidenav main"
      "sidenav footer";
  }

  .header__search {
    margin-left: 0;
  }

  .header__search {
    width: 350px;
  }

  .custom__search {
    width: 350px;
  }

  .sidenav {
    /* position: relative; */
    transform: translateX(0);
  }

  .main {
    padding: 25.25px 55px;
  }

  .main .section-title-ctn {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .user-area {
    display: block;
  }

  .user-area-art {
    display: block;
  }
}

@media (min-width: 768px) {
  .sidenav__close-icon {
    display: none;
  }
}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .info-cards {
    column-count: 2;
  }
}

.file-upload {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #8692a6;
  border-radius: 6px;
  cursor: pointer;
}

.upload-text {
  font-size: 13px;
  font-weight: 700;
  color: #030303;
}

.select-pcategory {
  height: 8rem;
}

.select-option {
  max-width: 15rem;
}

.serial-number {
  padding: 1.5rem;
}

.first-row:nth-child(2n + 1) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(205, 219, 227, 0.3) !important;
  font-size: 0.8rem;
  font-weight: 600;
}

.row-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
}

.table-header {
  font-size: 0.85rem;
  font-weight: 600 !important;
}

.featuredimage {
  display: flex;
  justify-content: flex-start;
  width: 100px;
  height: 100px;
}
