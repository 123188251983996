.view-table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}


.main-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
}

.heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 23px;
}

.texts {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 20px;
}

.download-payment{
  border: 1px solid #dddddd;
  padding: 0.50rem;
}

.side-tracker {
  border-left: dotted 1px #212529;
  padding: 0 0.5rem 0.5rem 2rem;
}

.indicator {
  margin-top: 6px;
  margin-right: 20px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #04a72d;
  animation: 0.9s infinite beatHeart;
  transform-origin: center;
  position: relative;
  left: -5px;
}

.yellowindicator{
  margin-top: 6px;
  margin-right: 20px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffc124;
  animation: 0.9s infinite beatHeart;
  transform-origin: center;
  position: relative;
  left: -5px;
}
.grayindicator{
  margin-top: 6px;
  margin-right: 20px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7f8e96;
  animation: 0.9s infinite beatHeart;
  transform-origin: center;
  position: relative;
  left: -5px;
}

.opacity{
  opacity: 0.3;
}

.red-indicator {
  margin-top: 6px;
  margin-right: 10px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f11010;
  animation: 0.9s infinite beatHeart;
  transform-origin: center;
  position: relative;
  left: -5px;
}

.tracker {
  display: flex;
  justify-content: flex-start;
}

.order-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: fit-content;
  text-align: center;
  height: 36px;
  background: #ffffff;
  color: #10f172;
  padding: 5px;
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgb(11 34 57 / 12%);
  margin-bottom: 10px;
  margin-left: 1.3rem;
}

.columns{
font-size: 0.8rem;
  font-weight: 600;
  border: none;
  padding: 15px 15px;
}

.order-row{

    height: 48px;
    background-color: rgba(205, 219, 227, 0.4) !important;
    font-size: 0.8rem;
    font-weight: 600;
    border: none;
    padding: 15px 15px;
}

.payment{
  font-size: 0.75rem;
  color: #f11010 !important;
}

.confirm-payment-btn{
  color: #ffffff;
  background-color: #04c457;
  border: 1px solid #04c457;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  font-size: 0.85rem;
  border-radius: 0;
  padding: 8px 10px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
