h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p {
  color: #282828;
}

* {
  font-family: "Mulish", sans-serif;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

input,
select,
textarea {
  box-shadow: none !important;
  outline: 0px !important;
}

button {
  box-shadow: none !important;
  outline: 0px !important;
}

input {
  height: 45px;
}

select {
  height: 45px;
}

.bg-warning {
  background-color: #ffc10794 !important;
}

.bg-primary {
  background-color: #0d6efdc9 !important;
}

.bg-info {
  background-color: #0dcaf0d9 !important;
}

.bg-success {
  background-color: #198754cf !important;
}

.bg-danger {
  background-color: #dc3545d1 !important;
}

.badge {
  font-size: 0.8rem;
  font-weight: 600;
}

.pag-ctn {
  width: 60%;
}

.lds-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #4f4e4e;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

/* //second loader */

.lds-ctnn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #4f4e4e;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* custom styleing for skeleton loader */
.category_home_page .ant-skeleton.ant-skeleton-element .ant-skeleton-input,
.category_home_page .ant-skeleton.ant-skeleton-element,
.category_home_page .ant-skeleton.ant-skeleton-element .ant-skeleton-image {
  width: 100% !important;
  height: 100%;
}

@media (max-width: 440px) {
  .pag-ctn {
    width: 100%;
  }
}
