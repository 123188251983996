.source_pro_button_ctn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.source_pro_button_ctn .source_pro_create_button {
  color: #fff;
  background-color: rgb(237, 92, 9);
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  outline: none;
  border: none;
}

.source_pro_button_ctn button {
  width: 150px;
}

.source_pro_button_ctn .source_pro_create_button:hover {
  background-color: rgb(245, 109, 31);
}

.source-pro-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px 10px;
}

.source-pro-wrapper input,
.source-pro-wrapper select {
  background-color: #ebeaea;
  border: 0.5px solid #fdfdfd;
  padding: 10px 5px !important;
  color: #a29f9f;
  border-radius: 10px;
}

.source-pro-wrapper input:focus,
.source-pro-wrapper select:focus {
  border: 1px solid rgb(245, 109, 31);
}
