.internet-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

@media (max-width: 768px) {
  .internet-ctn h1 {
    font-size: 22px;
    text-align: center;
  }
  .internet-ctn p {
    font-size: 14px;
    text-align: center;
  }
  .internet-ctn button {
    font-size: 14px;
  }
}
