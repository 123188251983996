.unauth-ctn {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.unauth-ctn .main {
  border-top: none;
}

.unauth-ctn h2 {
  font-family: "Mulish", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 32px;
}

.unauth-ctn .unauth-wrapper p {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.unauth-ctn .unauth-wrapper button {
  background: #dd4f05;
  border-radius: 4px;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 20px;
}

.unauth-svg-ctn {
  width: 100%;
}

.unauth-svg-ctn svg {
  width: 100%;
}

@media only screen and (min-width: 46.875em) {
  .unauth-ctn {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .unauth-svg-ctn {
    width: 50%;
  }
  .unauth-ctn .unauth-wrapper p {
    width: 80%;
    font-size: 18px;
    line-height: 23px;
  }
  .unauth-ctn h2 {
    font-size: 64px;
    line-height: 80px;
  }
}
