.information-box-left {
  width: 305px;
  height: 235px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 3rem;
  box-shadow: 0px 4px 20px rgb(11 34 57 / 12%);
}
.information-box-right {
  width: 505px;
  height: 235px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgb(11 34 57 / 12%);
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
}

