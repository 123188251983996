.unauthorized-container {
  padding: 40px;
}

.nf-text {
  font-weight: bolder;
  font-size: 16px;
  color: #000000;
}

.unauthorized-box {
  display: flex;
}

.unauthorized-content {
  margin-top: 120px;
}

.unauthorized-content h2 {
  font-weight: 800;
  font-size: 64px;
  line-height: 80px;
  color: #000000;
}

.unauthorized-content button,
.internet-ctn button {
  border: none;

  background: #dd4f05;
  border-radius: 4px;
  background-color: none;
  color: #fff;
  padding: 10px 30px;
  margin: 20px 0;
}

.unauthorized-image {
  position: relative;
}

.unauthorized-image img {
  width: 100%;
  height: auto;
}

.square {
  position: absolute;
  top: 20%;
  width: 60%;
  left: 20%;
}

.squareimages {
  display: flex;
}

.notfound {
  position: absolute !important;
  top: 50% !important;
  left: 20% !important;
}

.notfound h2 {
  font-size: 120px;
  color: #ffffff;
  font-weight: bolder;
}

.notfound p {
  font-size: 30px !important;
  color: #ffffff !important;
  font-weight: bolder !important;
}

.unauthorized-image-container {
  position: relative;
}

.hand1 {
  position: absolute;
  z-index: -1;
  right: 13%;
  top: 30%;
}

.hand2 {
  position: absolute;
  top: 30%;
  left: 13%;
  z-index: -1;
}

.hand2 img,
.hand1 img {
  height: 280px;
}

@media only screen and (max-width: 1200px) {
  .unauthorized-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .unauthorized-content,
  .unauthorized-image-container {
    width: 100%;
  }

  .notfound h2 {
    font-size: 50px;
  }

  .notfound p {
    font-size: 14px;
    color: #ffffff;
    font-weight: bolder;
  }

  .hand2 img,
  .hand1 img {
    height: 150px;
  }

  .unauthorized-content h2 {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  .unauthorized-container p {
    text-align: center !important;
  }

  .unauthorized-content {
    text-align: center;
  }
}
